<template>
  <div id="app" class="url" style="padding: 15px">
    <el-row>
      <el-col :span="24">
        <el-page-header @back="goBack" content="权限系统管理"> </el-page-header>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-button
        icon="el-icon-plus"
        type="success"
        @click="dialogFormVisible = true"
        class="add"
        >添加</el-button
      >
    </el-row>

    <!-- 添加信息弹框 -->
    <el-dialog
      title="添加信息"
      :visible.sync="dialogFormVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="系统名称">
          <el-input
            v-model="form.info"
            placeholder="请填写权限系统名称"
            autocomplete="off"
            style="width: 80%"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="appendInfo">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 编辑弹框 -->
    <el-dialog
      title="编辑权限类型"
      :visible.sync="dialogFormVisible1"
      width="30%"
      :close-on-click-modal="false"
    >
      <!-- 权限名称 select 选择器 -->
      <el-select
        style="width: 100%"
        v-model="value"
        multiple
        placeholder="请选择权限名称"
        @change="changeSelect"
      >
        <el-option
          v-for="item in permissionTypeList"
          :key="item.id"
          :label="item.smallSystemName"
          :value="item.smallSystemName"
          style="width: 100%"
        >
        </el-option>
      </el-select>
      <div slot="footer">
        <el-button @click="dialogFormVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="determineBtn">确 定</el-button>
      </div>
    </el-dialog>

    <el-table :data="permissionSysList" style="width: 100%" height="400">
      <el-table-column
        fixed
        type="index"
        label="序号"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="bigSystemName"
        property="name"
        label="权限系统名称"
        align="center"
      >
      </el-table-column>

      <el-table-column
        prop="jurisSmallSystems"
        property="type"
        label="权限类型"
        align="center"
        :formatter="fileData"
      >
      </el-table-column>

      <el-table-column fixed="right" label="操作" width="180" align="center">
        <template slot-scope="scope">
          <!-- 编辑 权限管理系统的 权限类型 -->
          <el-button
            @click="editPermissionType(scope.row)"
            type="primary"
            plain
            size="small"
            >编辑</el-button
          >
          <!-- 删除 权限系统管理的 权限类型 -->
          <el-button
            @click="deletPerSys(scope.row.bigSystemId)"
            type="danger"
            plain
            size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
// 大系统
import jurisApi from "@/api/managementApi/PermissionSysManage";
// 小系统
import jurisSmallSystem from "@/api/managementApi/PermissionTypeManage";
// 大小系统
import jurisBigSmallSys from "@/api/managementApi/JurisBigSmallSys";

export default {
  data() {
    return {
      getSmallSystemIdByName: {},
      dialogFormVisible: false, // 添加弹框 默认隐藏
      dialogFormVisible1: false, // 编辑信息弹框
      permissionTypeList: [], // 编辑权限类型
      smallSystemNameList: [], //编辑 下拉 列表
      value: "",
      sysRelation: [], // 系统关系
      form: {
        info: "",
      },
      permissionSysList: [], // 权限系统名称列表
      bigSystemId: "",
      smallSystemIds: [],
    };
  },
  created() {
    this.getPermissionSysNameList(); //权限系统名称
    this.getSysRelation(); // 获取大小系统关系
    // this.changeSelect()
  },

  methods: {
    fileData(row) {
      // console.log(row)

      let str = "";
      for (let i = 0; i < row.jurisSmallSystems.length; i++)
        str = str + "(" + row.jurisSmallSystems[i].smallSystemName + ")";

      return str;
    },
    //返回上一级
    goBack() {
      this.$router.push("/Select");
    },
    // 获取权限系统名称
    getPermissionSysNameList() {
      jurisBigSmallSys.getSystemInfo(0).then((res) => {
        console.log(res);
        this.permissionSysList = res.data.data.list;
      });
    },

    // 添加 权限系统名称
    appendInfo() {
      if (this.form.info != "") {
        jurisApi.addBigSystem(this.form.info).then(() => {
          // console.log(res);
          this.dialogFormVisible = false;
          this.form.info = "";
          this.getPermissionSysNameList();
        });
      } else {
        this.$message.error("系统名称不能为空！");
      }
    },

    // 删除系统
    deletPerSys(bigSystemId) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        //点击确定，指定then方法
        jurisApi.deleteJurisBigSystem(bigSystemId).then((res) => {
          //提示信息
          this.$message({
            type: res.data.success ? "success" : "warning",
            message: res.data.message,
          });
          //回到页面并刷新
          this.getPermissionSysNameList();
        });
      });
    },

    // 编辑权限类型     编辑按钮
    editPermissionType(row) {
      this.dialogFormVisible1 = true;
      // 查询已添加的所有权限类型
      jurisSmallSystem.getJurisSmallSystem().then((res) => {
        this.permissionTypeList = res.data.data.list;

        for (let i = 0; i < res.data.data.list.length; i++)
          this.getSmallSystemIdByName[res.data.data.list[i].smallSystemName] =
            res.data.data.list[i].id;
      });

      this.getThisSmallSystem(row);
    },

    // 编辑  -->  确定按钮
    determineBtn() {
      this.editPermissionSys(); // 根据大系统，编辑权限类型

      this.dialogFormVisible1 = false;
      this.$router.go(0); // 刷新
    },

    // 获取这个大系统的权限哪些选择了
    getThisSmallSystem(row) {
      // 查询已添加的所有权限类型
      this.value = [];

      this.bigSystemId = row.bigSystemId;
      jurisBigSmallSys.getSystemInfo(row.bigSystemId).then((res) => {
        for (
          let i = 0;
          i < res.data.data.list[0].jurisSmallSystems.length;
          i++
        ) {
          this.value.push(
            res.data.data.list[0].jurisSmallSystems[i].smallSystemName
          );
        }
      });
    },

    // 选择 下拉列表
    changeSelect(smallSystemName) {
      // console.log(smallSystemName);
      this.smallSystemNameList = smallSystemName; // 编辑下拉 列表
    },

    // 获取大小系统关系
    getSysRelation() {
      jurisBigSmallSys.getJurisBigSmallSystem().then((res) => {
        // console.log(res);
        this.sysRelation = res.data.data.list;
      });
    },

    // 根据大系统，编辑权限类型
    editPermissionSys() {
      var that = this;

      for (let i = 0; i < this.value.length; i++) {
        this.smallSystemIds.push(this.getSmallSystemIdByName[this.value[i]]);
      }

      jurisBigSmallSys
        .updateJurisBigSmallSystem(this.bigSystemId, this.smallSystemIds)
        .then(() => {
          that.bigSystemId = "";
          that.smallSystemIds = [];
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.add {
  margin: 10px 10px;
}
</style>